import type { BaseLayoutProps } from '../../types';

import * as React from 'react';
import dynamic from 'next/dynamic';
import { DefaultLayout } from '../DefaultLayout';

// IMPORTANT: The page.name in the import and switch case must match with the pageType set in the BaseLayoutProps definition!
import { Layout as LayoutGame } from '../../generated/game/layout';
import { Layout as LayoutIndex } from '../../generated/index/layout';
const NavigationBar = dynamic<any>(() => import('../../components/NavigationBarG').then((mod) => mod.NavigationBarG), { ssr: true });
const FooterBar = dynamic<any>(() => import('../../components/FooterBarF').then((mod) => mod.FooterBarF), { ssr: true });

export function BaseLayout(props: BaseLayoutProps) {
  const { children, pageType, pageContext } = props;
  return (
    <>
      <NavigationBar/>
      {(() => {
        switch (pageType) {
          case 'game':
            return <LayoutGame pageContext={pageContext}>{children}</LayoutGame>;
          case 'index':
            return <LayoutIndex pageContext={pageContext}>{children}</LayoutIndex>;
          default:
            return <DefaultLayout pageContext={pageContext}>{children}</DefaultLayout>;
        }
      })()}
      <FooterBar/>
    </>
  );
}

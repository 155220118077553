import * as React from 'react';
import styled from '@emotion/styled';
import { AdvertisementSlot } from '../../components/AdvertisementSlot';
import { useAppContext } from '../../contexts/AppContext';
import { AGGRESSIVE_HOME_BOTTOM_ADS_CONFIGURATION } from '../../types/static';
import { Container, useMediaQuery, useTheme } from '@mui/material';

const SectionBottom = styled(Container)(({ theme }) => ({
  display: 'none',
  '@media screen and (max-width: 600px)': {
    display: 'block',
    marginTop: theme.spacing(5),
    width: 300,
    height: 250,
  },
}));

const AdvertisementBottomSlot = styled(AdvertisementSlot)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  backgroundColor: 'grey',
}));

const AdvertisementBottom = ({ slotIdentifier }: { slotIdentifier: string }) => {
  return (
    <AdvertisementBottomSlot
      configuration={AGGRESSIVE_HOME_BOTTOM_ADS_CONFIGURATION}
      areaName="aggresiveHometBottomAds"
      elevation={1}
      slotIdentifier={slotIdentifier}
      variant="300x250"
    />
  );
};

export const AggressiveHomeBottomAds = () => {
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <SectionBottom>
      {sm && <AdvertisementBottom slotIdentifier={componentOptions?.aggressive_home_bottom_id || ''} />}
    </SectionBottom>
  );
};

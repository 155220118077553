import type { PaperProps } from '@mui/material';

import styled from '@emotion/styled';
import { darken, lighten } from '@mui/material';
import Paper from '@mui/material/Paper';

export interface PaperColorElevationProps extends PaperProps {
  index?: number;
  totallength?: number;
}

const PaperStyled = styled(Paper)<PaperColorElevationProps>(({ theme, elevation }) => {
  return {
    boxShadow: theme.shadows[elevation || 1],
    backgroundColor:
      theme.palette.mode === 'dark'
        ? lighten(theme.palette.background.paper, elevation ? elevation * 0.025 : 0)
        : darken(theme.palette.background.paper, elevation ? elevation * 0.025 : 0),
  };
}) as typeof Paper;

export const PaperColorElevation = (props: PaperColorElevationProps) => {
  const { children, ...other } = props;
  return <PaperStyled {...other}>{children}</PaperStyled>;
};
